/* Custom flash effect for cell value change */
@keyframes flashRed {
  0% {
    background-color: rgb(250, 100, 100);
    transform: scale(1); /* Scale up to 120% */
  }
  10% {
    background-color: rgb(250, 100, 100);
    transform: scale(1.1); /* Scale up to 120% */
  }
  100% {
    background-color: transparent;
    transform: scale(1); /* Scale up to 120% */
  }
}

@keyframes flashGreen {
  0% {
    background-color: rgb(100, 250, 125);
    transform: scale(1); /* Scale up to 120% */
  }
  10% {
    background-color: rgb(100, 250, 125);
    transform: scale(0.9); /* Scale up to 120% */
  }
  100% {
    background-color: transparent;
    transform: scale(1); /* Scale up to 120% */
  }
}

.ag-flash-red {
  animation: flashRed 1.5s forwards !important;
}

.ag-flash-green {
  animation: flashGreen 1.5s forwards !important;
}
